.agreement {
    font-family: Arial, Helvetica, sans-serif;
}

.agreement h1 {
    text-align: center;
    color: #70CF17;
    font-size: 26px;
    font-weight: 600;
    margin: 50px 0;
}

.agreement h2 {
    color: rgb(75, 43, 107);
    font-size: 20px;
    margin: 0 0 14px;
}

.agreement p {
    font-size: 14px;
    line-height: 1.5;
    margin: 0 0 14px;
}

.agreement strong {
    font-weight: 600;
}

.agreement ol {
    counter-reset: item;
    font-size: 14px;
    line-height: 1.3;
    margin: 0;
    padding: 0;
}

.agreement ol li {
    display: block;
    position: relative;
    padding-left: 40px;
}

.agreement ol li:before {
    content: counters(item, ".") ". ";
    counter-increment: item;
    position: absolute;
    left: 0;
    top: 0;
}

.agreement ul {
    list-style: disc;
    font-size: 14px;
    line-height: 1.3;
}

.agreement .outerList {
    margin-bottom: 16px;
    margin-left: -40px;
    font-weight: 400;
}

.agreement .innerList {
    margin-left: 20px;
    font-weight: 400;
}

.agreement .definitionStyling {
    font-weight: 400
}

.agreement .mainList {
    font-weight: 600;
    margin-bottom: 16px;
}